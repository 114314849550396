<!-- 收藏 -->
<template>
	<div class="shop">
		<div>
			<van-list v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="10"
				finished-text="我是有底线的" @load="onLoadList" style="padding: 15px 15px 50px 15px; height: 100%">
				<div v-for="(item, index) in goodsList" :key="index" class="goods-item" @click="indexDetails(item.id)"
					v-if="dataStatus">
					<van-swipe-cell>
						<div style="display: flex; align-items: center; padding: 5px 15px">
							<!-- <img :src="imageUrl + item.image" alt="" class="goods-image" /> -->
							<img :src="item.image" alt="" class="goods-image">
							<div class="shop-info">
								<span class="goods-name">{{ item.name }}</span>
								<span style="font-size: 13px; color: #666">{{
                  item.add_time
                }}</span>
								<span style="font-size: 18px; font-weight: bold; color: red">￥{{ item.price }}</span>
							</div>
						</div>
						<template #right>
							<van-button square type="danger" text="删除" @click="delList(item.id)"
								class="delete-button" />
						</template>
					</van-swipe-cell>
				</div>
				<van-empty :image="emptyImg" description="暂无关注" v-else />
			</van-list>
		</div>
	</div>
</template>
<script>
	import {
		collectList,
		delList
	} from "@/api/user";
	// import { toUrl } from "@/utils/tools";
	import http from "@/utils/config";
	// import { Dialog } from "vant";
	export default {
		data() {
			return {
				dataStatus: true,
				shopType: "",
				nullTip: "空空如也~",
				imageUrl: http.imageUrl,
				limit: 10, // 每页条数
				page: 1, //页码
				type: 1,
				goodsList: [], //请求数据
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
			};
		},
		mounted() {
			this.collectList();
		},
		methods: {
			indexDetails(id) {
				console.log(id)
				this.$router.push({
					path: "/h5/commDetail",
					query: {
						id: id
					}
				})
			},
			collectList() {
				this.$toast.loading({
					message: "加载中...",
					forbidClick: true,
				});
				let params = {};
				params.page = this.page;
				params.limit = this.limit;
				collectList(params).then((res) => {
					console.log(res)
					const rows = res.data.data;
					if (rows.length === 0) {
						// 加载结束
						this.upFinished = true;
						if (this.page == 1) {
							this.dataStatus = false;
							this.$toast.clear();
						}
						return;
					} else {
						this.dataStatus = true;
					}
					if (rows.length < this.limit) {
						// 最后一页不足10条的情况
						this.upFinished = true;
					}
					// 处理数据
					if (this.page === 1) {
						this.goodsList = rows;
					} else {
						this.goodsList = this.goodsList.concat(rows);
					}
					// console.log(this.goodsList)
					this.$toast.clear();
				});
			},
			onDownRefresh() {},
			delList(id) {
				// console.log(id)
				let params = {};
				params.pid = id;
				delList(params).then((res) => {
					if (res.code == 200) {
						// console.log(res)
						this.page = 1;
						this.upFinished = false;
						this.goodsList = [];
						this.collectList();
					}
				});
			},
			// 上拉加载请求方法
			onLoadList() {
				this.page++;
				this.collectList();
			},
		},
	};
</script>
<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.shop {
		.goods-item::v-deep(.van-swipe-cell__right) {
			height: 100% !important;
			top: 0 !important;
			right: -2px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		background-color: #f6f6f6;

		.options-area {
			display: flex;
			flex-wrap: wrap;
			background-color: #ffffff;

			.options {
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 20%;
				margin-bottom: 10px;

				.options-icon {
					width: 55px;
					height: 55px;
				}

				.options-name {
					margin-top: 5px;
					font-size: 12px;
					color: #666666;
				}
			}
		}

		.goods-item {
			display: flex;
			align-items: flex-start;
			background-color: #ffffff;
			// height: 88px;
			border-radius: 5px;
			margin-bottom: 10px;
			// padding: 5px 15px;
			justify-content: flex-start;

			.van-swipe-cell {
				width: 100%;
			}

			.delete-button {
				height: 100% !important;
			}

			.goods-image {
				width: 88px;
				height: 88px;
				border-radius: 8px;
			}

			.shop-info {
				margin-left: 10px;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.goods-name {
					font-size: 16px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}

				.shop-address-item {
					display: flex;
					align-items: center;

					.shop-address {
						font-size: 12px;
						color: #666666;
					}
				}
			}
		}
	}
</style>
